<template>
  <div class="card shadow">
    <h3 class="border-start border-3 border-warning ps-4 text-start p-2">مرحبا مدير النظام</h3>
  </div>
</template>
<script>
export default {

}
</script>
<style></style>