<template>
  <router-view/>
</template>
<script>
export default {
  created() {

  }
}
</script>
<style>
table{
  padding: 10px;
}
</style>

